li {
  list-style-type: none;
}

html,
body {
  height: 100%;
}

.loggedInAs {
  padding-right: 16px;
  color: #a78c58;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.container {
  font-family: Lato;
  background-color: #fff7ee;
  /* width: 100vw; */

  /* min-width: 300px; */
  /* height: 100vh; */
  padding: 0;
  margin: 0;

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  overflow-x: hidden !important;
}

.chat {
  background: #eaeaea;
  color: #434651;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
}

.chatHeader {
  padding: 20px;
  border-bottom: 2px solid white;
  background-color: #fff;
  color: #a78c58;
}

.chatHistory {
  height: 40vh;
  padding: 10px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: auto;
  position: relative;
}

.error {
  font-weight: 600;
  margin-left: 32px;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
  color: red;
}

.myMessageDetails {
  font-size: 14px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.messageTime {
  color: #aaacc7;
}

.messageAuthor {
  color: #aaacc7;
  text-transform: capitalize;
}

.circleBlue {
  width: 8px;
  height: 8px;
  background-color: #ccb58f;
  border-radius: 100%;
  margin-left: 4px;
}

.circleGreen {
  width: 8px;
  height: 8px;
  background-color: #8bb3bc;
  border-radius: 100%;
  margin-right: 4px;
}

.myMessageContent {
  background-color: #ccb58f;
  color: white;
  padding: 10px 14px;
  /* line-height: 26px; */
  border-radius: 12px 0 12px 12px;
  margin-bottom: 12px;
  margin-left: auto;
  font-size: 14px;
  position: relative;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
}

.myMessageContent::after {
  top: 0;
  right: -8px;
  border: 10px solid transparent;
  border-radius: 5px;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #ccb58f;
  transform: rotate(180deg);
}

.otherMessageDetails {
  font-size: 14px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.otherMessageContent {
  background-color: #8bb3bc;
  color: white;
  padding: 10px 14px;
  line-height: 26px;
  border-radius: 0 12px 12px 12px;
  margin-bottom: 12px;
  max-width: 100%;
  position: relative;
  font-size: 14px;
  text-align: left;
  box-sizing: border-box;
  display: inline-block;
}

.otherMessageContent::after {
  top: 0;
  left: -8px;
  border: 10px solid transparent;
  border-radius: 5px;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #8bb3bc;
  border-width: 10px;
  transform: rotate(180deg);
}

/* form */
form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat-message {
  padding: 30px;
  box-sizing: content-box;
}

form textarea {
  font-family: Lato;
  width: 80%;
  height: 48px;
  margin: auto;
  border: none;
  padding: 10px;
  border-radius: 5px;
  box-sizing: content-box;
}

.sendAndEmojiContainer {
  position: relative;
  display: flex;
  align-items: center;
}
.emojiPicker {
  position: absolute;
  bottom: 0;
  right: 0;
}

.send {
  padding: 16px 12px;
  color: #7ea4d7;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background-color: transparent;
}

.send:hover {
  padding: 16px 12px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: transparent;
}

.noMatchIdContainer {
  font-family: Lato;
  background-color: #fff7ee;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.signoutBtn{
  text-transform:none !important;
  padding: 0 14px !important;
  margin-top:8px !important;
  color: #a78c58!important;
  border-radius: 25px !important;
  background-color: #fff !important;
  border-color: #a78c58 !important;
}

.confirmBtn{
  text-transform:none !important;
  padding: 0 14px !important;
  margin-bottom:8px !important;
  color: #7da080!important;
  border-radius: 25px !important;
  background-color: #fff !important;
  border-color: #7da080 !important;
  align-self: center !important;
}

.firstSystemMessage{
  background-color: #fff;
  color: #555;
  padding:10px;
  border-radius: 8px;
  margin: 10px 20px 10px;
  top: 0;
  z-index: 99;
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
  .chat {
    background: #eaeaea;
    color: #434651;
  }
  .flexContainer {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    width: 100% !important;
  }
}
