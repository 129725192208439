.root {
  background: #fef8f4;
  min-height: 100vh;
  padding: 16px 0;
  position: relative;
}
.wrapper {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.faq-title {
  text-align: center;
  color: #9e7666;
  font-weight: 600;
  font-size: 24px;
  margin-top: 16px;
}
.faq-section-title {
  text-align: center;
  color: #9e7666;
  font-weight: 600;
  font-size: 20px;
  margin-top: 40px;
}
.accordion {
  margin-top: 20px;
  width: 100%;
}

.item {
  border-top: 2px solid #9e7666;
  cursor: pointer;
  padding: 10px 0px;
}

.question-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h3 {
  width: 90%;
}

.answer {
  width: 90%;
  color: black;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
}

.answer.show {
  height: auto;
  max-height: 9999px;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
}

.symbol {
  font-size: 30px;
}
