.root {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.wrapper {
  text-align: center;
  width: 80%;
}

img {
  max-width: 100%;
}

.rocket-img {
  transform:rotate(-90deg);
}

h1 {
  font-size: 3rem;
  font-family: 'Schnyder Wide S Light';
  font-weight: 400;
  line-height: 51px;
  letter-spacing: 0em;
}

p{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}

.img-background {
  background-color: #F89AEE;
  border-radius: 50px;
}

.btn {
  display: block;
  margin: 0 auto;
  width: 80%;
  background-color: #FFA621;
  color: #FFFFFF;
  border: none;
  font-weight: bold;
  border-radius: 50px;
  font-size: 2rem;
  text-decoration: none;
  padding: 20px;
}

.footer-text {
  margin-top: 50px;
  color: #8F8C8C;
  font-size: 10px;
}

.title-screen-container {
  background: #fef8f4;
}

.title-screen-container, .start-screen-container {
  background: #fef8f4;
}

.orange-background {
  background: #FFA47C;
}

.blue-background {
  background: #80BAC7;
  transform: rotate(-180deg);
}

.question {
  margin-top: 20px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 47px;
  color: #FFFFFF;
}

.player {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  color: #FFFFFF;
}

.how-to-play {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 116.7%;
  color: #8F4B4B;
}

.btn-desc {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #FFFFFF;
}

.rating-container {
  display: inline-flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 20px;
  color: #FFD7C6;
  padding: 5px 25px;
  border-radius: 5px 5px 0 0;
  background-color: #8F4B4B;
}

.arrowUp {
  margin: 0 10px;
}
.arrowDown {
  margin-left: 10px;
}