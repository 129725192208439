.pop{
  background-color: #ccc;
  text-align: center;
  position: relative;
  margin-bottom: -15px;
  border-radius: 8px;
  padding: 4px 0;
  color: #9e7666;
  font-size: 14px;
  justify-content: center;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #eee !important;
}

.MuiOutlinedInput-root fieldset{
  border-color: #eee;
}

.MuiOutlinedInput-root:hover fieldset{
  border-color: #eee !important;
  outline: none;
}

.MuiFormLabel-root.Mui-focused,
.css-u4tvz2-MuiFormLabel-root.Mui-focused{
  color:#000 !important;
}

.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1; 
  -webkit-box-orient: vertical;
}

.three-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.four-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}






.slider {
  -webkit-appearance: none;
  display:block;
  margin:auto;
  width: 90%;
  height: 8px;
  background-image: linear-gradient(to right, #efd3ab , #ff8315);
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin-bottom: 40px;
  border-radius: 10px;
}

.slider:hover {
  opacity: 0.8;
}

.center {
  text-align: center;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 50px;
  height: 50px;
  border: 15px;
    border-radius: 10%;
  background-image: url(./Components/Assets/slider.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

.flex{
  display: flex;
}

.align-items-center{
  align-items: center;
}

.space-between{
  justify-content: space-between;
}

#floating-snap-btn-wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 85%;
  left: 90%;
  width: 80px;
  height: 80px;
  z-index: 999;
}

#floating-snap-btn-wrapper .fab-btn {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}